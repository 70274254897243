import { Button, Col, Form, Image, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import spinner from "../images/spinner/spinner.gif";
import { api, reCaptcha } from "../config/config";
import axios from "axios";
import Divider from "../widgets/general/Divider";
import Partners from "../sub-components/about/Partners";
import qebg from "../images/backgrounds/qebg4.mp4";
import ReCAPTCHA from "react-google-recaptcha";

const Airdrop = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [ios, setIos] = useState(false);

  useEffect(() => {
    document.title = "QE Coin - Airdrop Inbound";
    window.scrollTo(0, 0);
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  function captchaHandler(value) {
    console.log(value);
    setCaptchaValue(value);
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setError("");
    const nameRegex = new RegExp(/^[A-z _'-]{2,20}$/i);
    let emailGood = false;
    let nameGood = false;
    let addressGood = false;
    let captchaGood = false;

    if (captchaValue !== null) {
      setCaptchaError(false);
      captchaGood = true;
    } else {
      setCaptchaError(true);
    }
    if (validator.isEthereumAddress(address)) {
      addressGood = true;
      document.getElementById("address").className = "form-control";
    } else {
      addressGood = false;
      document.getElementById("address").className = "form-control is-invalid";
    }

    if (nameRegex.test(name)) {
      nameGood = true;
      document.getElementById("name").className = "form-control";
    } else {
      nameGood = false;
      document.getElementById("name").className = "form-control is-invalid";
    }

    if (validator.isEmail(email)) {
      emailGood = true;
      document.getElementById("email").className = "form-control";
    } else {
      emailGood = false;
      document.getElementById("email").className = "form-control is-invalid";
    }

    if (emailGood && nameGood && addressGood && captchaGood) {
      document.getElementById("button").disabled = true;
      setShowSpinner(true);
      await axios
        .post(`${api}qe-coin-airdrop`, {
          name,
          email,
          address,
          reCaptcha: captchaValue,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.result === true) {
            console.log("true");
            setShowSpinner(false);
            setShowForm(false);
            navigate("/airdrop/success?m=1");
          } else {
            if (res.data.reason === "existing") {
              console.log("existing");

              setShowSpinner(false);
              setShowForm(false);
              navigate("/airdrop/success?m=2");
            } else if (res.data.status === 429) {
              setShowSpinner(false);
              setError(
                "You have tried too many times and have now reach a timeout."
              );
            } else {
              document.getElementById("button").disabled = false;
              setShowSpinner(false);
              setError("Something went wrong, please try again.");
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 429) {
            setShowSpinner(false);
            setError(
              "You have tried too many times and have now reach a timeout."
            );
          } else {
            document.getElementById("button").disabled = false;
            setShowSpinner(false);
            setError("Something went wrong, please try again.");
          }
        });
    }
  };
  return (
    <div>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: "0",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                zIndex: -1,
              }}>
              <source src={qebg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "0",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
            }}>
            <div className="px-3 py-6 pb-10">
              <div
                className="mt-20 mb-10 mx-auto"
                style={{ maxWidth: "1000px" }}>
                <div className="mb-5">
                  <h1 className="text-white text-center display-1">
                    Airdrop Inbound
                  </h1>
                  <div className="d-flex justify-content-center">
                    <Col xl={11} lg={11} md={11} sm={11} xs={12}>
                      <p className="text-white lead">
                        Are you ready to dive into a revolutionary new universal
                        rewards token? Introducing QE Coin - the token that
                        stands for "Question Everything". WE follow the adage of
                        the ancient philosophers to continually grow, create,
                        innovate and build solutions for a better world. Welcome
                        to the journey!
                      </p>
                    </Col>
                  </div>
                </div>
                <div className="mb-5">
                  <h2 className="text-center text-white">
                    Be Part of the QE Coin Airdrop
                  </h2>
                  <div className="d-flex justify-content-center">
                    <Col xl={8} lg={8} md={8} sm={10} xs={12}>
                      <p className="text-white">
                        We are thrilled to announce our upcoming airdrop
                        happening late summer or early fall! This is your
                        exclusive chance to get your hands on QE Coins before
                        they hit the market. Signing up is quick, easy, and
                        absolutely free.
                      </p>
                    </Col>
                  </div>
                </div>
                <div className="mb-5">
                  <h2 className="text-center text-white">
                    How to Join the Airdrop
                  </h2>
                  <div className="d-flex justify-content-center">
                    <Col xl={6} lg={6} md={6} sm={10} xs={12}>
                      <ul className="text-white">
                        <li className="mb-1">
                          <strong>Fill Out the Form</strong>: Provide us with
                          some basic information to register your interest.
                        </li>
                        <li className="mb-1">
                          <strong>Stay Updated</strong>: Follow us on social
                          media and check your email for updates.
                        </li>
                        <li className="mb-1">
                          <strong>Receive Your Coins</strong>: Once the airdrop
                          goes live, you'll receive your free QE Coins straight
                          to your wallet.
                        </li>
                      </ul>
                    </Col>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center pb-10">
                <span
                  type="button"
                  onClick={() => setShowForm(true)}
                  style={{ width: "400px", maxWidth: "100%" }}
                  className="btn btn-outline-white-trans">
                  Click Here for QE Coins
                </span>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="shadow-lg px-0"
          style={{ border: "$primary" }}
          centered
          show={showForm}
          onHide={() => setShowForm(false)}>
          <Modal.Header
            style={{ border: "none" }}
            className="border-0 shadow-none pb-0">
            <Modal.Title
              style={{ border: "none" }}
              className="text-center w-100">
              <div className="d-flex justify-content-between w-100">
                <h3 className="mb-0 text-white">Airdrop Registration</h3>
                <span
                  type="button"
                  onClick={() => setShowForm(false)}
                  style={{ position: "relative" }}
                  className="close-button">
                  X
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ border: "none" }}
            className="rounded-bottom pb-5">
            <div className="d-flex justify-content-center">
              <Form
                onSubmit={submitHandler}
                noValidate
                style={{ width: "90%" }}>
                <p className="text-danger">{error}</p>
                <Form.Group className="mb-3">
                  <Form.Label className="text-white">
                    <span className="text-danger">*</span>
                    Email Address
                  </Form.Label>
                  <Form.Control
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter your email address"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="text-white">
                    <span className="text-danger">*</span>
                    Name
                  </Form.Label>
                  <Form.Control
                    id="name"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="What should we call you?"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="text-white">
                    <span className="text-danger">*</span>
                    Wallet Address
                  </Form.Label>
                  <Form.Control
                    id="address"
                    type="text"
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Enter your wallet address (0x...)"
                    required
                  />
                  <Form.Text className="text-muted">
                    If you don't have a wallet yet,{" "}
                    <Link to="#">click here</Link> to learn how.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="myCaptcha" className="mb-5">
                  <div
                    className={` ${
                      captchaError
                        ? "border border-1 border-danger"
                        : "border-none"
                    }`}
                    style={{ width: "fit-content", borderRadius: "4px" }}>
                    <div
                      style={{
                        marginRight: "-1px",
                        marginBottom: "-2px",
                        alignContent: "center",
                        height: "100%",
                      }}>
                      <ReCAPTCHA
                        sitekey={reCaptcha}
                        onChange={captchaHandler}
                      />
                    </div>
                  </div>
                  {captchaError && (
                    <p className="text-danger mb-0">
                      Please check the reCaptcha box.
                    </p>
                  )}
                </Form.Group>

                <Button
                  type="submit"
                  as="button"
                  id="button"
                  className="w-100 btn btn-outline-secondary-trans">
                  Submit
                </Button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showSpinner} fullscreen>
          <Image width={150} src={spinner} className="m-auto" />
        </Modal>
      </div>
      <Divider />
      <Partners />
    </div>
  );
};

export default Airdrop;
