import { Routes, Route } from "react-router-dom";
import Landing from "../pages/Landing";
import Airdrop from "../pages/Airdrop";
import Success from "../pages/Success";
import About from "../pages/About";
import Tokenomics from "../pages/Tokenomics";
import Innovation from "../pages/Innovation";
import Partners from "../sub-components/about/Partners";
import EmailVerification from "../pages/EmailVerification";

export default function AllRoutes() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/airdrop" element={<Airdrop />} />
        <Route exact path="/airdrop/success" element={<Success />} />
        <Route exact path="/tokenomics" element={<Tokenomics />} />
        <Route exact path="/innovation" element={<Innovation />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route
          exact
          path="/email-verification"
          element={<EmailVerification />}
        />

        <Route path="/*" element={<Landing />} />
      </Routes>
    </div>
  );
}
