// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import FollowUs from "../../sub-components/socials/Socials";

const Footer = () => {
  const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <div
        style={{
          bottom: "0",
          width: "100%",
          zIndex: "990",
        }}
        className="bg-trans shadow-lg">
        <Row className="justify-content-center mx-lg-10">
          <Col className="text-center">
            <div className="">
              <div className="fs-4 mt-4">
                <h5 className="display-7 text-white">Follow Us</h5>
                <FollowUs gap={5} />
              </div>
              <div className="mt-4 mb-0">
                <p className="mb-0 text-white">QVMF Inc. dba QE Coin</p>
                <p className="text-white mb-0">
                  Registered in Saint Kitts and Nevis
                </p>
                <p className="text-white">{`© ${year}. All Rights Reserved.`}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Footer;
