import { Fragment } from "react";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import qebg from "../../images/backgrounds/qebg4.mp4";
const AirdropInfo = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
            }}
          >
            <video
              autoPlay
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <source src={qebg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "0",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
            }}
          >
            <div
              className="py-20 px-3 pb-10"
              style={{
                minHeight: "100vh",
                alignContent: "center",
                position: "relative",
                zIndex: "1",
              }}
            >
              <div className="mb-5 mt-10 mx-auto" style={{ maxWidth: "900px" }}>
                <h2 className="text-white text-center display-3">
                  Airdrop Inbound
                </h2>
                <div className="d-flex justify-content-center">
                  <Col xl={11} lg={11} md={11} sm={11} xs={12}>
                    <p className="text-white lead">
                      Are you ready to dive into a revolutionary new universal
                      rewards token? Introducing QE Coin - the token that stands
                      for "Question Everything." WE follow the adage of the
                      ancient philosophers to continually grow, create, innovate
                      and build solutions for a better world. Thus, “Question
                      Everything”.
                    </p>
                  </Col>
                </div>
              </div>
              <div className="d-flex justify-content-center mb-15">
                <Button
                  style={{ width: "500px", minWidth: "250px" }}
                  onClick={() => navigate("/airdrop")}
                  className="btn btn-outline-white-trans fs-3 fw-bold mx-10 mb-10 shadow-lg"
                >
                  Get Free QE Coins
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AirdropInfo;
