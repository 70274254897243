import { useEffect } from "react";
import Divider from "../widgets/general/Divider";
import Roadmap from "../sub-components/about/Roadmap";
import QeIntro from "../sub-components/about/QeIntro";
import Partners from "../sub-components/about/Partners";

const About = () => {
  useEffect(() => {
    document.title =
      "QE Coin - Revolutionizing Rewards by Questioning Everything";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <QeIntro />
      <Divider />
      <Roadmap />
      <Divider />
      <Partners />
    </div>
  );
};

export default About;
