import { Fragment } from "react";
import { Button, Card, Row, Image } from "react-bootstrap";
import entertainment from "../../images/icons/entertainment.svg";
import giving from "../../images/icons/giving.svg";
import shopping from "../../images/icons/shopping.svg";
import { useNavigate } from "react-router-dom";
import socrates from "../../images/signatures/socrates.png";

const FocusPoints = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row className="d-flex justify-content-center gap-5 px-3 mb-5">
        <Card
          style={{
            width: "400px",
            minWidth: "300px",
            border: "1px solid",
          }}
          className="bg-trans border-secondary"
        >
          <div
            style={{
              position: "absolute",
              marginLeft: "20px",
              marginTop: "20px",
            }}
          >
            <Image height={50} src={entertainment} />
          </div>
          <h3 className="text-white text-center pt-5 pb-2 display-6">
            Entertainment
          </h3>
          <p className="text-white px-3">
            {`Discover a new way to get rewarded for your leisure activities. With QE Coin, you can earn tokens while watching your favorite streaming platforms, video games, reading books, and listening to podcasts. Not only do you get rewarded, but content creators also benefit by receiving QE Coins for their work. Enhance your entertainment experience with tangible rewards.`}
          </p>
        </Card>
        <Card
          style={{
            width: "400px",
            minWidth: "300px",
            border: "1px solid",
          }}
          className="bg-trans border-secondary"
        >
          <div
            style={{
              position: "absolute",
              marginLeft: "20px",
              marginTop: "20px",
            }}
          >
            <Image height={50} src={giving} />
          </div>
          <h3 className="text-white text-center pt-5 pb-2 display-6">Giving</h3>
          <p className="text-white px-3">
            {`Make your contributions count even more. QE Coin is revolutionizing the way we give by rewarding users and contributors for their participation in crowdfunding campaigns and non-profit initiatives. Whether you’re donating or helping to raise funds, QE Coin ensures that your efforts are recognized and appreciated.`}
          </p>
        </Card>{" "}
        <Card
          style={{
            width: "400px",
            minWidth: "300px",
            border: "1px solid",
          }}
          className="bg-trans border-secondary"
        >
          <div
            style={{
              position: "absolute",
              marginLeft: "20px",
              marginTop: "20px",
            }}
          >
            <Image height={50} src={shopping} />
          </div>
          <h3 className="text-white text-center pt-5 pb-2 display-6">
            Shopping
          </h3>
          <p className="text-white px-3">
            Turn your everyday shopping into a rewarding experience with QE
            Coin. Earn tokens on every purchase, whether you’re shopping online
            or in-store. Redeem your QE Coins for exclusive rewards, discounts,
            or exchange them as you wish. Simplify your shopping and maximize
            your rewards with QE Coin.
          </p>
        </Card>
      </Row>
      <div className="d-flex justify-content-center">
        <Button
          style={{ width: "500px" }}
          onClick={() => navigate("/about")}
          className="btn btn-outline-white-trans fs-3 fw-bold mx-10 mb-10 mt-5 shadow-lg"
        >
          <p className="mb-0 fs-3 fw-bold" style={{ zIndex: 500 }}>
            Learn More
          </p>
        </Button>
      </div>
      <div style={{ maxWidth: "450px" }} className="mx-auto mt-10">
        <div className="text-white fs-3 d-flex mx-3 roboto-light">
          "
          <p className="text-uppercase">
            I know you won't believe me, but the highest form of Human
            Excellence is to question oneself and others."
          </p>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <Image fluid className="text-white fs-3" src={socrates} width={250} />
        </div>
      </div>
    </Fragment>
  );
};
export default FocusPoints;
