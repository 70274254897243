const config = {
  port: process.env.PORT || 3000,
  reCaptcha: "6LfTCAIqAAAAAFx4N6cavwSN24MmBni1hPAirtv8",

  // Production-specific configuration
  production: {
    api: "https://dv-api-e55b1e7a73db.herokuapp.com/",
  },

  // Development-specific configuration
  development: { api: "http://localhost:5000/" },
};

const environment = process.env.NODE_ENV || "development";
const environmentConfig = config[environment];

// Merge the common and environment-specific configurations
const finalConfig = { ...config, ...environmentConfig };

module.exports = finalConfig;
