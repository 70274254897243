import { Fragment, useState } from "react";
import { Card, Image, Row } from "react-bootstrap";
import einstein from "../../images/signatures/einstein.png";

const TokenomicsInfo = () => {
  const [minted, setMinted] = useState("0");
  return (
    <Fragment>
      <div className="px-2">
        <h2 className="display-3 text-white text-center mb-5">Tokenomics</h2>
        <Row className="d-flex justify-content-center gap-5 mb-5">
          <Card
            style={{
              width: "400px",
              minWidth: "300px",
              border: "1px solid",
            }}
            className="bg-trans border-secondary">
            <h3 className="text-white text-center pt-4 pb-3 display-6">
              963 Million Max Supply
            </h3>
          </Card>
          <Card
            style={{
              width: "400px",
              minWidth: "300px",
              border: "1px solid",
            }}
            className="bg-trans border-secondary">
            <h3 className="text-white text-center pt-4 pb-3 display-6">
              1% Burn Rate
            </h3>
          </Card>
          <Card
            style={{
              width: "400px",
              minWidth: "300px",
              border: "1px solid",
            }}
            className="bg-trans border-secondary">
            <h3 className="text-white text-center pt-4 pb-3 display-6">
              {minted} Minted QE Coins
            </h3>
          </Card>
        </Row>
        <Row className="text-white fs-3">
          <div style={{ maxWidth: "1000px" }} className="mx-auto">
            <p
              style={{ fontSize: "1.4rem" }}
              className="text-white fw-bold text-center">
              There will only be 963 million QE Coin in existence.
            </p>
            <p>
              The number 963 is in homage to one of the greatest innovators,
              inventors, creators the world has ever seen – Nikola Tesla.
              There’s a unique Universal Truth about the numbers 369 and 963.
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="text-white fw-bold text-center">
              QE Coin embraces what is possible and impossible.
            </p>
            <p>
              QE Coin or the ‘Question Everything’ Coin is a way to build a
              robust community and ecosystem to create the best possible
              outcomes. WE look at the analogy of the Ocean. The Ocean thrives
              and is healthy as long as the fish and coral grow and expand -
              everything benefits each other and the whole.
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="text-white fw-bold text-center">
              The QE Coin Philosophy is to be transparent from the beginning.
            </p>
          </div>
        </Row>
        <div style={{ maxWidth: "550px" }} className="mx-auto mt-20">
          <div className="text-white fs-3 d-flex mx-3 text-uppercase roboto-light ">
            "
            <p>
              The important thing is not to stop questioning. Curiosity has its
              own reason for existing."
            </p>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <Image
              fluid
              className="text-white fs-3"
              src={einstein}
              width={325}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default TokenomicsInfo;
