import { Fragment, useEffect, useState } from "react";
import TokenomicsInfo from "../sub-components/about/TokenomicsInfo";
import Divider from "../widgets/general/Divider";
import Partners from "../sub-components/about/Partners";
import qebg from "../images/backgrounds/qebg.mp4";

const Tokenomics = () => {
  const [ios, setIos] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "QE Coin - Tokenomics";
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <Fragment>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
              pointerEvents: "none",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                zIndex: -1,
              }}>
              <source src={qebg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              backgroundColor: "#0000003d",
              position: "relative",
              zIndex: 500,
            }}
            className="py-10 py-lg-15">
            <div className="mx-3 py-15" style={{ position: "relative" }}>
              <TokenomicsInfo />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <Partners />
    </Fragment>
  );
};
export default Tokenomics;
