import { Fragment, useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import Tokenomics from "./TokenomicsInfo";
import qebg from "../../images/backgrounds/qebg.mp4";

const QeIntro = () => {
  const [ios, setIos] = useState(false);

  useEffect(() => {
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <Fragment>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: "0",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                zIndex: -1,
              }}>
              <source src={qebg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              backgroundColor: "#0000003d",
              position: "relative",
              zIndex: 500,
            }}>
            <div
              className="mx-3 pb-20 pt-5 pt-sm-0"
              style={{ position: "relative" }}>
              <div
                style={{
                  position: "relative",
                  minHeight: "100vh",
                  alignContent: "center",
                  height: "100%",
                }}>
                <Row className="mt-20 mt-sm-10 mt-md-0">
                  <p className="text-white display-2 text-center">
                    Join the Blockchain Renaissance
                  </p>
                </Row>
                <Row>
                  <div style={{ maxWidth: "1000px" }} className="mx-auto">
                    <p className="text-white fs-3">
                      At QE Coin, WE believe in disruption, innovation, and
                      expansion of ideas and technology. Our mission is to
                      transform the way you earn, spend, and enjoy rewards,
                      making it easier and more enjoyable for everyone.
                    </p>
                    <p className="text-white fs-3">
                      WE envision a world where every interaction can be an
                      opportunity to earn and enjoy rewards. QE Coin aims to
                      bridge the gap between digital and real-world tokenized
                      assets, creating a seamless experience for users and
                      businesses alike.
                    </p>
                    <p className="text-white fs-3">
                      A Universal reward token designated for equity
                      crowdfunding and building a community to enhance everyday
                      activities, from shopping, entertainment, giving, and
                      beyond.
                    </p>
                  </div>
                </Row>
              </div>
              <div className="mt-20 mt-sm-10 mt-md-0">
                <Tokenomics />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default QeIntro;
