import { useEffect, useState } from "react";
import FocusPoints from "../sub-components/landing/FocusPoints";
import Hero from "../sub-components/landing/Hero";
import AirdropIntro from "../sub-components/landing/AirdropIntro";
import Divider from "../widgets/general/Divider";
import Partners from "../sub-components/about/Partners";
import qebg from "../images/backgrounds/qebg.mp4";

const Landing = () => {
  const [ios, setIos] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "QE Coin - Revolutionizing Rewards by Questioning Everything";
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "fit-content",
          overflow: "hidden",
          minHeight: "100vh",
          alignContent: "center",
        }}
        className="bg-black">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            overflow: "hidden",
            pointerEvents: "none",
            zIndex: "0",
          }}>
          <video
            autoPlay={!ios}
            muted
            loop
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              pointerEvents: "none",
              zIndex: -1,
            }}>
            <source src={qebg} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div
          style={{
            backgroundColor: "#0000003d",
            position: "relative",
            zIndex: 500,
          }}
          className="py-20">
          <div>
            <Hero />
            <FocusPoints />
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <AirdropIntro />
      </div>
      <Divider />
      <Partners />
    </div>
  );
};

export default Landing;
