import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import Socials from "../sub-components/socials/Socials";
import Partners from "../sub-components/about/Partners";
import Divider from "../widgets/general/Divider";
import qebg from "../images/backgrounds/qebg4.mp4";

const Success = () => {
  const [searchParams] = useSearchParams();
  const m = searchParams.get("m");
  const [type, setType] = useState(null);
  const [ios, setIos] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
    if (m === "1") {
      setType(1);
    } else {
      setType(2);
    }
  }, [m]);

  return (
    <div>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: "0",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                zIndex: -1,
              }}>
              <source src={qebg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "0",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
            }}
            className="mx-2 py-15">
            <div className="py-15 mx-auto" style={{ maxWidth: "1000px" }}>
              {type === 1 && (
                <div className="d-flex justify-content-center mb-5">
                  <div style={{ width: "1000px" }}>
                    <div className="mb-5">
                      <h1 className="text-white text-center display-3">
                        You're In!
                      </h1>
                      <div className="d-flex justify-content-center">
                        <Col xl={11} lg={11} md={11} sm={11} xs={12}>
                          <p className="text-white lead">
                            We have received your information and you are now
                            set to receive QE Coins when the token launches.{" "}
                            <strong>
                              Please verify your email address by clicking the
                              link we emailed you.
                            </strong>{" "}
                            Failure to do this may result in losing your
                            eligibility to be included in the airdrop. If you do
                            not see the email, check your junk or spam folder.
                          </p>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {type === 2 && (
                <div className="d-flex justify-content-center mb-5">
                  <div style={{ width: "1000px" }}>
                    <div className="mb-5">
                      <h1 className="text-white text-center display-3">
                        You're Already In!
                      </h1>
                      <div className="d-flex justify-content-center">
                        <Col xl={10} lg={10} md={10} sm={10} xs={11}>
                          <p className="text-white lead">
                            We have already received your information and you
                            are set to receive QE Coins when the token launches.{" "}
                            <strong>
                              Please verify your email address by clicking the
                              link we emailed you.
                            </strong>{" "}
                            Failure to do this may result in losing your
                            eligibility to be included in the airdrop. If you do
                            not see the email, check your junk or spam folder.
                          </p>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-5">
                <h2 className="text-center text-white fw-bold">
                  Be Part of the QE Coin{" "}
                  <span className="text-nowrap">Comm-UNITY</span>
                </h2>
                <div className="d-flex justify-content-center">
                  <Col xl={8} lg={8} md={8} sm={10} xs={12}>
                    <p className="text-white fs-4">
                      Make sure you follow us on our social media accounts and
                      join our community by using the links below. Consider
                      sharing the QE Coin Airdrop with your friends too.
                    </p>
                    <Socials gap={5} />
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <Partners />
    </div>
  );
};
export default Success;
