import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./routes/AllRoutes";
import DefaultLayout from "./layouts/DefaultLayout";

const App = () => {
  const Layout = DefaultLayout;
  return (
    <div className="App">
      <Router>
        <Layout>
          <AllRoutes />
        </Layout>
      </Router>
    </div>
  );
};

export default App;
