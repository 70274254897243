import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../config/config";
import axios from "axios";
import { Col } from "react-bootstrap";
import Socials from "../sub-components/socials/Socials";
import Partners from "../sub-components/about/Partners";
import Divider from "../widgets/general/Divider";
import qebg from "../images/backgrounds/qebg4.mp4";

const EmailVerification = () => {
  const params = new URLSearchParams(useLocation().search);
  let email = decodeURIComponent(params.get("email"));
  let key = decodeURIComponent(params.get("key"));
  const [pass, setPass] = useState(null);
  const [reason, setReason] = useState(null);
  const [ios, setIos] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
    verification();
  }, []);

  const verification = async () => {
    await axios
      .post(`${api}qe-email-verification`, { email, key })
      .then((res) => {
        console.log(res.data);
        setPass(res.data.result);
        setReason(res.data.reason);
      })
      .catch((err) => {
        console.error(err);
        if (err.response.data === "timeout") {
          setPass(false);
          setReason("timeout");
        } else if (
          err.response.reason === "bad key" ||
          err.response.reason === "not found"
        ) {
          setPass(false);
          setReason("data");
        } else {
          setPass(false);
          setReason("error");
        }
      });
  };

  return (
    <div>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: "0",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                zIndex: -1,
              }}>
              <source src={qebg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "0",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
            }}
            className="mx-2 py-15">
            <div className="py-15 mx-auto" style={{ maxWidth: "1000px" }}>
              {pass && (
                <div className="d-flex justify-content-center mb-5">
                  <div style={{ width: "1000px" }}>
                    <div className="mb-5">
                      <h1 className="text-white text-center display-3">
                        You're All Set!
                      </h1>
                      <div className="d-flex justify-content-center">
                        <Col xl={11} lg={11} md={11} sm={11} xs={12}>
                          <p className="text-white lead">
                            Your email address has been verified, and your spot
                            in the QE Coin airdrop is now secured. Stay tuned
                            for future updates via email and our social
                            channels.
                          </p>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!pass && (
                <div className="d-flex justify-content-center mb-5">
                  <div style={{ width: "1000px" }}>
                    <div className="mb-5">
                      <h1 className="text-white text-center display-3">
                        Something Went Wrong
                      </h1>
                      <div className="d-flex justify-content-center">
                        <Col xl={10} lg={10} md={10} sm={10} xs={11}>
                          {reason === "timeout" && (
                            <p className="text-danger lead">
                              You have made too many attempts to verify your
                              email address and have reached a security timeout.
                              Please ensure you are using the link provided in
                              the email we sent you and try again at a later
                              time.
                            </p>
                          )}
                          {reason === "data" && (
                            <p className="text-danger lead">
                              We were unable to verify your email address with
                              the information provided. Please ensure you are
                              using the link in the email we sent you and try
                              again.
                            </p>
                          )}
                          {reason === "data" && (
                            <p className="text-danger lead">
                              An error occurred while verifying your email.
                              Please ensure you are using the link provided in
                              the email we sent you and try again.
                            </p>
                          )}
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-5">
                <h2 className="text-center text-white fw-bold">
                  Be Part of the QE Coin{" "}
                  <span className="text-nowrap">Comm-UNITY</span>
                </h2>
                <div className="d-flex justify-content-center">
                  <Col xl={8} lg={8} md={8} sm={10} xs={12}>
                    <p className="text-white fs-4">
                      Make sure you follow us on our social media accounts and
                      join our community by using the links below. Consider
                      sharing the QE Coin Airdrop with your friends too.
                    </p>
                    <Socials gap={5} />
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <Partners />
    </div>
  );
};
export default EmailVerification;
