import { Fragment } from "react";

const Hero = () => {
  return (
    <Fragment>
      <div className="mb-10 mx-2 pt-10">
        <h2 className="text-white display-5 text-center">
          The Future of Rewards
        </h2>
        <h1 className="text-white display-3 text-center mb-3">
          Revolutionizing by Questioning Everything
        </h1>
        <p
          style={{ fontSize: "1.2rem" }}
          className="text-white text-center fw-bold"
        >
          It's time we rethink how people are rewarded for doing business and
          interacting with each other.
        </p>
      </div>
    </Fragment>
  );
};

export default Hero;
